<template>
  <div class="card p-1" :style="`font-family:${font},sans-serif`">
    <div class="title head">
      <p class="h2" style="color: #558cef">ค้นหาวัสดุ</p>
    </div>
    <hr />
    <div class="custom-search">
      <!-- advance search input -->
      <b-row>
        <b-col md="4">
          <b-form-group>
            <label>เลขทะเบียน:</label>
            <b-form-input v-model="material_code" placeholder="ค้นหา" type="text" class="d-inline-block" />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group>
            <label>รหัสวัสดุ:</label>
            <b-form-input v-model="m_id" placeholder="ค้นหา" type="text" class="d-inline-block" />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group>
            <label>รายการ:</label>
            <b-form-input v-model="m_name" placeholder="ค้นหา" type="text" class="d-inline-block" />
          </b-form-group>
        </b-col>
        <!-- <b-col md="4">
          <b-form-group>
            <label>กลุ่มงาน:</label>
            <b-form-input
              placeholder="ค้นหา"
              type="text"
              class="d-inline-block"
            />
          </b-form-group>
        </b-col> -->
        <div class="row col-3 mb-1">
          <div class="ml-1">
            <b-button variant="success" @click="searchTable"> ค้นหา </b-button>
          </div>
          <div class="ml-1">
            <b-button variant="danger"> ยกเลิก </b-button>
          </div>
        </div>
      </b-row>
    </div>

    <!-- table -->
    <b-overlay :show="show" rounded="sm">
      <vue-good-table
        :columns="columns"
        :rows="rows"
        :group-options="{
          enabled: true,
          headerPosition: 'bottom',
        }"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm,
        }"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
        :line-numbers="true"
      >
        <template slot="table-row" slot-scope="props">
          <!-- Column: Name -->
          <span v-if="props.column.field === 'fullName'" class="text-nowrap">
            <b-avatar :src="props.row.avatar" class="mx-1" />
            <span class="text-nowrap">{{ props.row.fullName }}</span>
          </span>
          <span v-else-if="props.column.field === 'รอเบิก'">
            <div class="text-right">{{ props.row.รอเบิก }}</div>
          </span>
          <span v-else-if="props.column.field === 'เบิก'">
            <div class="text-right">{{ props.row.เบิก }}</div>
          </span>
          <span v-else-if="props.column.field === 'คงเหลือ'">
            <div class="text-right">{{ props.row.คงเหลือ }}</div>
          </span>
          <span v-else-if="props.column.field === 'ราคาต่อหน่วย'">
            <div class="text-right">{{ props.row.ราคาต่อหน่วย }}</div>
          </span>

          <!-- Column: Action -->
          <span v-else-if="props.column.field === 'viw'">
            <span>
              <b-button variant="outline-warning" @click="dateField(props)">
                <feather-icon icon="EyeIcon" class="mr-50" />
              </b-button>
            </span>
          </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap"> Showing 1 to </span>
              <b-form-select
                v-model="pageLength"
                :options="['5', '10', '15', '25', '50', '100']"
                class="mx-1"
                @input="(value) => props.perPageChanged({ currentPerPage: value }, handleChangePage1(value))"
              />

              <span class="text-nowrap">of {{ total }} entries</span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="handlePagechange1($event)"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-overlay>
  </div>
</template>

<script>
import { BAvatar, BPagination, BFormGroup, BFormInput, BFormSelect, BRow, BCol } from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { VueGoodTable } from "vue-good-table";
import axios from "axios";
import API from "@/views/connectDB/condb.js";

export default {
  components: {
    VueGoodTable,
    BAvatar,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  data() {
    return {
      total: "",
      pageLength: 50,
      currentPage: 1,
      dir: false,
      show: false,
      columns: [
        {
          label: "เลขทะเบียน",
          field: "material_code",
          filterOptions: {
            enabled: true,
            placeholder: "ค้นหา เลขทะเบียน",
          },
        },
        {
          label: "หมายเลขวัสดุ",
          field: "m_id",
          filterOptions: {
            enabled: true,
            placeholder: "ค้นหา หมายเลขวัสดุ",
          },
        },

        {
          label: "ชื่อวัสดุ",
          field: "m_name",
          thClass: "text-center",
          filterOptions: {
            enabled: true,
            placeholder: "ค้นหา ชื่อวัสดุ",
          },
        },
        {
          label: "หน่วยนับ",
          field: "unittype",
          filterOptions: {
            enabled: true,
            placeholder: "ค้นหา หน่วยนับ",
          },
        },
        {
          label: "ราคาต่อหน่วย",
          field: "unitprice",
          type: "number",
          filterOptions: {
            enabled: true,
            placeholder: "ค้นหา ราคาต่อหน่วย",
          },
          sortable: true,
          formatFn: this.formatFn,
        },
        {
          label: "ราคา + vat 7%",
          field: "unitprice_vat",
          type: "number",
          filterOptions: {
            enabled: true,
            placeholder: "ค้นหา ราคา + vat 7%",
          },
          sortable: true,
          formatFn: this.formatFn,
        },
        {
          label: "รับเข้า",
          field: "amount",
          tdClass: "text-center",
          thClass: "text-center",
          width: "5%",
          filterOptions: {
            enabled: true,
            placeholder: "ค้นหา รับเข้า",
          },
        },
        {
          label: "ราคาจำนวนเต็มรวม VAT",
          field: "totalprice_vat",
          tdClass: "text-center",
          thClass: "text-center",
          width: "5%",
          sortable: true,
          formatFn: this.formatFn,
          filterOptions: {
            enabled: true,
            placeholder: "ค้นหา รับเข้า",
          },
          headerField: this.sumCount,
        },
        {
          label: "เบิก",
          tdClass: "text-center",
          thClass: "text-center",
          field: "req_amount",
          width: "5%",
          filterOptions: {
            enabled: true,
            placeholder: "ค้นหา เบิก",
          },
        },
        {
          label: "คงเหลือ",
          field: "remain",
          width: "6%",
          tdClass: "text-center",
          thClass: "text-center",
          filterOptions: {
            enabled: true,
            placeholder: "ค้นหา คงเหลือ",
          },
        },
        // {
        //   label: "วันที่",
        //   field: "วันที่",
        // },
        {
          label: "ดูรายการ",
          field: "viw",
          width: "6%",
        },
      ],
      rows: [],
      searchTerm: "",
      m_id: "",
      material_code: "",
      m_name: "",
      font: "",
    };
  },
  computed: {
    example: {
      cache: false,
      get() {
        return Date.now() + this.msg;
      },
    },
  },
  async created() {
    const font = localStorage.getItem("font");
    console.log(font);
    this.font = font;
    // this.checkLogout();
  },
  methods: {
    sumCount(rowObj) {
      // console.log(rowObj);
      let sum = 0;
      for (let i = 0; i < rowObj.children.length; i++) {
        sum += Number(rowObj.children[i].totalprice_vat);
      }
      // console.log(sum);
      if (sum.toString() == "NaN") {
        sum = "";
        return sum;
      } else {
        return sum;
      }
    },
    async checkLogout() {
      console.log("ตรวจสอบออกจากระบบ");
      const { username } = await JSON.parse(localStorage.getItem("username_type"));
      console.log(username);
      axios
        .get("https://eoffice.niets.or.th/verify/auth", {
          headers: {
            "px-auth-token": `${username}`,
          },
        })
        .then((response) => {
          console.log(response.status);
          if (response.status === 200) {
            console.log(" User อยู่ในระบบ");
          }
        })
        .catch((res) => {
          const { response } = res;
          console.log(response);
          if (response.status != 200) {
            console.log(" User ออกจากระบบเเล้ว");
            this.$router.push({ name: "logoutUser" });
          }
        });
      console.log("-------------------------");
    },
    formatFn(value) {
      return Number(value)
        .toFixed(2)
        .toString()
        .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    },
    async dateField(props) {
      this.$router.push({
        name: "inseawassadu",
        params: {
          rowData: props.row, // or anything you want
        },
      });
    },

    handlePagechange1(event) {
      this.currentPage = event;
      this.getdatatable();
    },
    handleChangePage1(event) {
      this.pageLength = event;
      this.getdatatable();
    },
    searchTable() {
      if (this.m_id == "" && this.material_code === "" && this.m_name === "") {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "กรุณากรอกข้อมูลค้นหาวัสดุ",
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        this.getdatatable();
      }
    },
    async getdatatable() {
      this.show = true;
      console.log("getdata.........................................................................");
      const { access_token } = await this.access_token();
      const header = {
        headers: {
          Authorization: await access_token,
        },
      };
      // console.log(res_2)
      const url_1 = `${API}materialSearchTableExamine?m_id=${this.m_id}&material_code=${this.material_code}&m_name=${this.m_name}&_page=${this.currentPage}&_limit=${this.pageLength}`;
      const res_1 = await axios.get(url_1, header);
      const url_2 = `${API}materialSearchTablePickup?material_code=${this.material_code}&m_id=${this.m_id}&m_name=${this.m_name}&_page=${this.currentPage}&_limit=${this.pageLength}&sumAmount=${res_1.data.message.sumAmount}`;
      const res_2 = await axios.get(url_2, header);

      console.log(res_1);
      console.log(res_2);

      console.log(res_1.data.message.total);
      console.log(res_2.data.message.total);

      const t_1 = Number(res_1.data.message.total) + Number(res_2.data.message.total);
      this.total = t_1;
      this.rows = [...res_1.data.message.data, ...res_2.data.message.data];

      console.log(t_1);
      console.log(this.rows);
      const data_sum = [
        {
          material_code: "รวม",
          m_id: "",
          children: this.rows,
        },
      ];
      this.rows = data_sum;
      console.log(this.rows);

      this.show = false;
    },

    async access_token() {
      return (
        await axios.post(`${API}permit`, "", {
          headers: {
            Authorization: localStorage.getItem("storedData") && JSON.parse(localStorage.getItem("storedData")),
          },
        })
      ).data.message;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
